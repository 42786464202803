import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Viewport from "../components/Viewport"

const Features = () => (
  <Layout>
    <Seo title="Emerald Wallet Features" />

    <section className="features">
      <Viewport threshold={0} rootMargin="0px 0px -200px 0px">
        <h2>
          <span>Ethereum and Bitcoin</span>  Support
        </h2>
        <p>
          Emerald Wallet is designed to support both Ethereum and Bitcoin blockchains.
          In addition to native assets (ETH and BTC), it also supports ERC-20 tokens on the Ethereum blockchain.
          This broad compatibility enables users to manage various assets through a single interface, making it an optimal choice for those who wish to maintain a diversified cryptocurrency portfolio.
        </p>
      </Viewport>

      <Viewport threshold={0} rootMargin="0px 0px -200px 0px">
        <h2>
          <span>Desktop</span> Application
        </h2>
        <p>
          Emerald Wallet is available as a desktop application that is compatible with Windows, macOS, and Linux operating systems.
          The desktop-focused approach ensures a robust and feature-rich environment for managing cryptocurrencies.
          Additionally, the development team has announced that a mobile version is in the roadmap, which will further enhance accessibility and convenience for users on the go.
        </p>
      </Viewport>

      <Viewport threshold={0} rootMargin="0px 0px -200px 0px">
        <h2>
          <span>User-Friendly</span> Interface
        </h2>
        <p>
          Emerald Wallet is designed to be intuitive and accessible, even for users who are not tech-savvy.
          The wallet simplifies standard operations such as sending and receiving transactions, while also offering advanced options for experienced users.
          For instance, users can manage and set custom fees per transaction, access raw transaction details, and more, ensuring that the wallet caters to a wide range of user preferences and needs.
        </p>
      </Viewport>

      <Viewport threshold={0.2} rootMargin="0px 0px -200px 0px">
        <h2>
          <span>Infrastructure</span> Support
        </h2>
        <p>
          Emerald Wallet is backed by a sophisticated infrastructure that provides access to blockchain nodes, transaction history, and real-time balance updates.
          This infrastructure eliminates the need for users to run a full node, while still allowing them to have full control over their funds and transactions.
          It's a balance that combines convenience with empowerment.
        </p>
      </Viewport>

      <Viewport threshold={0.3} rootMargin="0px 0px -200px 0px">
        <h2>
          <span>Control Your Keys</span> with Encrypted Vault
        </h2>
        <p>
          Emerald Wallet is a non-custodial wallet, meaning only you have access to your private keys and addresses.
          Additionally, your keys are encrypted and stored in a local vault on your device, ensuring that they are not transferred or exposed to any external servers.
          This security model helps to protect your assets and maintain your privacy.
        </p>
      </Viewport>

      <Viewport threshold={0.4} rootMargin="0px 0px -200px 0px">
        <h2>
          <span>Import and Compatibility</span> with Other Wallets
        </h2>
        <p>
          Emerald Wallet uses standard Seed Phrases, supporting 12 or 24 words, which is common among cryptocurrency wallets.
          Additionally, it supports Ethereum Raw Private Keys and Ethereum JSON key formats, enabling easy migration or recovery from other wallets.
          This compatibility ensures that users can switch to Emerald Wallet without losing access to their existing balances.
        </p>
      </Viewport>

      <Viewport threshold={0.5} rootMargin="0px 0px -200px 0px">
        <h2>
          <span>Open Source</span> Transparency
        </h2>
        <p>
          We think that transparency is crucial for trust and security in the cryptocurrency space.
          Therefore, Emerald Wallet's source code is available on <a href="https://github.com/emeraldpay/emerald-wallet">GitHub</a>, allowing anyone to review and audit the code.
          Users and developers are welcome to inspect the codebase to ensure it meets their requirements and contribute if they wish.
        </p>
      </Viewport>

      <Viewport threshold={0.5} rootMargin="0px 0px -200px 0px">
        <h2>
          <span>Ledger Hardware Wallet</span> Integration
        </h2>
        <p>
          For users seeking an additional layer of security, Emerald Wallet integrates with Ledger hardware wallets, including the Ledger Nano S and Ledger Nano X.
          By storing private keys on a hardware device, users can safeguard their assets against online threats and ensure that their funds are secure even if their computer is compromised.
        </p>
      </Viewport>

      <Viewport threshold={0.5} rootMargin="0px 0px -200px 0px">
        <h2>
          Extensive <span>Stable Coin</span> Support
        </h2>
        <p>
          Emerald Wallet recognizes the importance of stable coins by offering extensive support for major stable coins such as USDC, USDT, and DAI.
          These assets are integrated seamlessly within the wallet, allowing users to easily manage and transact in stable coins alongside other cryptocurrencies.
        </p>
      </Viewport>

      <Viewport threshold={0.5} rootMargin="0px 0px -200px 0px">
        <h2>
          <span>Actively Developed</span> with a Vision
        </h2>
        <p>
          Emerald Wallet has been under active development for several years, and the team is committed to continuous improvements.
          The roadmap includes new features such as multi-signature support, a mobile version, browser plugin integration, and a command-line wallet.
          The team is committed to continue the development to make it the most helpful instrument for active cryptocurrency users.
        </p>
      </Viewport>

      <Viewport threshold={0.6} rootMargin="0px 0px -200px 0px">
        <h2>
          <span>Help</span> and Support
        </h2>
        <p>
          Emerald Wallet offers dedicated help and support channels to ensure that users have the assistance they need.
          For general queries and guidance, users can visit the <a href="https://help.emerald.cash">help website</a>, which hosts a range of resources, tutorials, and FAQs.
          If you have have any issue with the wallet, please submit a support ticket through the same website.
        </p>
      </Viewport>

    </section>

    <section>
      <Viewport
        threshold={0}
        rootMargin="0px 0px -200px 0px"
        className={"center"}
      >
        <Link to="download">
          <button>Download</button>
        </Link>
      </Viewport>
    </section>
  </Layout>
)

export default Features
